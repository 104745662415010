<template src="./SendNotificationChangePassword.html"></template>

<script>
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import loginService from "@/services/Login";
import { songs } from "@/utils/constants";
import { nodeInstance, phpInstance } from "@/plugins/axios";

export default {
  name: "sendNotificationChangePassword",
  components: { ArrobaMedellinLogo },
  data() {
    return {
      songs,
      emailsSelect: [],
      emails: [],
    };
  },
  mounted() {
    this.getEmails();
  },
  methods: {
    async getEmails() {
      try {
        const emails = await loginService.getEmailsUsersByDocument(
          this.$store.state.User.user.document
        );
        this.emails = emails;
      } catch (e) {
        console.log(e);
        this.songs.ALERT.play();
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async sendNotification() {
      let emailSelect = null;
      this.emailsSelect.forEach((email, index) => {
        if (email) {
          emailSelect = this.emails[index];
        }
      });

      try {
        await loginService.notifyChangePasswordUserController({
          email: emailSelect,
        });
        const infoUser = await loginService.getUserAuthByRol({
          userId: JSON.stringify(this.$store.state.User.user.id),
          token: this.$store.state.User.token,
        });
        this.$store.dispatch("fetchUser", infoUser.user);
        this.$store.dispatch("fetchJwt", infoUser.tokenNode);
        this.$store.dispatch("fetchToken", infoUser.tokenPhp);
        nodeInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${infoUser.tokenNode}`;
        phpInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${infoUser.tokenPhp}`;
        this.$store.dispatch("fetchAuth", true);
        this.$router.push({ path: `/` });
      } catch (e) {
        console.log(e);
        this.songs.ALERT.play();
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
  computed: {
    selectCheckbox() {
      var validate = true;
      this.emailsSelect.forEach((emailsSelect) => {
        if (emailsSelect === true) {
          validate = false;
        }
      });
      return validate;
    },
  },
};
</script>

<style lang="scss" src="./SendNotificationChangePassword.scss" scoped></style>
